/**
 * Implements a gated lander using the legacy design
 *
 * See: https://nbox-341.atlassian.net/browse/WEB-1361
 */
import React from 'react'
import GetStartedPromoLander from '~/routes/GetStarted/PromoLander'
import { cleanJsonStrings } from '~/utils/unicode'
import { graphql, useStaticQuery } from 'gatsby'
import { CMS } from '~/cms/types'

/**
 * This value should remain constant even if this lander is hosted in a new slug
 * See: https://github.com/butcherbox/butcherbox-frontend/pull/937 for more context
 */
const UNIQUE_PAGE_ID = 'groundbeef-forlife'

/**
 * Until we have clarity on which lander designs perform the best, we are hosting some
 * landers at static URLs
 */
export default function () {
  const { pageData, memberPerksImage } = cleanJsonStrings(
    useStaticQuery<{
      pageData: CMS.ProtectedPage<CMS.ProductOfferLander>
      memberPerksImage: {
        childImageSharp: { fluid: any }
      }
    }>(graphql`
      query {
        pageData: contentfulProtectedPage(
          pageId: { eq: "signup-interstitial" }
        ) {
          ...ProtectedPage
        }

        memberPerksImage: file(
          relativePath: { eq: "steak_on_serving_board_crop_center.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1441, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)
  )
  return (
    <GetStartedPromoLander
      memberPerksImage={memberPerksImage?.childImageSharp?.fluid || {}}
      pageData={pageData}
      uniquePageId={UNIQUE_PAGE_ID}
    />
  )
}
